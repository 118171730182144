<template>
  <!-- Online -->
  <se-badge v-if="isOnline" type="success" v-bind="$attrs">
    <div class="flex items-center">
      <div class="h-8 w-8 rounded-full bg-success-100 !mr-4" />
      Online
    </div>
  </se-badge>
  <!-- Offline -->
  <se-badge v-else-if="isOffline" type="default" v-bind="$attrs">
    <div class="flex items-center">
      <div class="h-8 w-8 rounded-full bg-neutral-60 !mr-4" />
      Offline
    </div>
  </se-badge>
  <!-- Busy -->
  <se-badge v-else type="warning" v-bind="$attrs">
    <div class="flex items-center">
      <div class="h-8 w-8 rounded-full bg-warning-120 !mr-4" />
      Busy
    </div>
  </se-badge>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import SeBadge from '~/shared/ui-kit/badge/Badge.vue';

const props = defineProps<{
  advisor: any
}>();
console.log('props.advisor.status', props.advisor.status === 'offline' && props.advisor.last_online, props.advisor.status, props.advisor.last_online);
const isOnline = computed(() => props.advisor.status === 'online');
const isOffline = computed(() => props.advisor.status === 'offline');
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorStatus',
};
</script>
